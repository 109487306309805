import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Section } from '../Section';
import { SectionTitle } from '../Typography';
import { mediaQueries } from 'src/style/breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import colors from 'src/style/colors';
import ButtonScrollToForm from '../LandingPage/ButtonScrollToForm';

const RecruitersSection = React.memo(({ theme, landing, fromPage }) => {
  const data = useStaticQuery(graphql`
    query {
      all: allFile(filter: { relativePath: { regex: "/recruiters/" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(layout: FIXED, quality: 70, height: 40, placeholder: NONE)
            }
          }
        }
      }
    }
  `);

  return (
    <Section theme={theme ? theme : 'light'} landing={landing}>
      <SectionTitle
        isCenter
        css={{
          paddingBottom: 60,
        }}
      >
        Les entreprises qui ont <span css={{ color: colors.purple }}>recruté</span> nos&nbsp;élèves
      </SectionTitle>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          [mediaQueries.tabletLandscapeUp]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          },
          [mediaQueries.phoneOnly]: {
            marginBottom: 20,
          },
        }}
      >
        {data.all.edges.map(({ node }) => {
          return (
            <div
              key={node.name}
              css={{
                paddingRight: landing ? 10 : 20,
                paddingLeft: landing ? 10 : 20,
                marginBottom: '1em',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                maxWidth: 150,
                maxHeight: 70,
                margin: landing ? 10 : 20,
                [mediaQueries.phoneOnly]: {
                  paddingRight: 10,
                  paddingLeft: 10,
                  margin: 15,
                  maxWidth: 100,
                },
              }}
            >
              <GatsbyImage
                imgStyle={{
                  verticalAlign: 'middle',
                  objectFit: 'contain',
                }}
                image={node.childImageSharp.gatsbyImageData}
                alt={'Logo ' + node.name}
              />
            </div>
          );
        })}
      </div>
      {landing && (
        <ButtonScrollToForm
          text={
            fromPage === 'reunion'
              ? `Je participe à la réunion d'information\u00A0!`
              : `Je postule\u00A0!`
          }
          margin
        />
      )}
    </Section>
  );
});

export default RecruitersSection;
