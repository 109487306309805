import React from 'react';
import { Section } from '../../components/Section';
import { SectionTitle } from '../Typography';
import { mediaQueries } from 'src/style/breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

const PartnersProSection = React.memo(({ title, theme }) => {
  const data = useStaticQuery(graphql`
    query {
      all: allFile(filter: { relativePath: { regex: "/partners-pro/" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(quality: 70)
            }
          }
        }
      }
    }
  `);
  return (
    <Section theme={theme}>
      <SectionTitle
        isCenter
        css={{
          paddingBottom: 60,
        }}
      >
        {title}
      </SectionTitle>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          [mediaQueries.tabletLandscapeUp]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          },
          [mediaQueries.phoneOnly]: {
            marginBottom: 20,
          },
        }}
      >
        {data.all.edges.map(({ node }) => {
          return (
            <div
              key={node.name}
              css={{
                paddingRight: 20,
                paddingLeft: 20,
                marginBottom: '1em',
                display: 'inline-block',
                width: '100%',
                maxWidth: 150,
                maxHeight: 70,
                margin: 20,
              }}
            >
              <GatsbyImage
                imgStyle={{
                  verticalAlign: 'middle',
                  maxHeight: 70,
                  maxWidth: 170,
                  objectFit: 'contain',
                }}
                image={node.childImageSharp.gatsbyImageData}
                alt={'Logo ' + node.name}
              />
            </div>
          );
        })}
      </div>
    </Section>
  );
});

export default PartnersProSection;
