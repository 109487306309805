import React from 'react';
import { Section } from '../../components/Section';
import { SectionTitle } from '../Typography';
import { StaticImage } from 'gatsby-plugin-image';

import { mediaQueries } from 'src/style/breakpoints';

const PartnersSection = React.memo(({ title, theme }) => {
  return (
    <Section
      theme={theme ? theme : 'light'}
      css={{
        [mediaQueries.phoneOnly]: {
          padding: '80px 0 90px 0',
        },
        [mediaQueries.tabletPortraitUp]: {
          padding: '110px 0 130px 0',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SectionTitle
          isCenter
          css={{
            paddingBottom: 60,
          }}
        >
          {title}
        </SectionTitle>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: ' center',
            alignItems: 'center',
            [mediaQueries.tabletLandscapeUp]: {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            },
          }}
        >
          <div css={{ width: 250, display: 'flex', justifyContent: 'center' }}>
            <StaticImage
              src="../../data/images/partners/startup-garage.png"
              // fadeIn={false}
              // placeholder="none"
              css={{
                marginBottom: 62,
                width: 250,
                [mediaQueries.tabletLandscapeUp]: {
                  marginBottom: 40,
                },
              }}
              alt="Logo Startup Garage Facebook"
            />
          </div>
          <div css={{ width: 250, display: 'flex', justifyContent: 'center' }}>
            <StaticImage
              src="../../data/images/partners/lafrenchtech1.png"
              // fadeIn={false}
              // placeholder="none"
              css={{
                marginBottom: 62,
                height: 70,
                width: 80,
                [mediaQueries.tabletLandscapeUp]: {
                  marginBottom: 40,
                },
              }}
              alt="Logo La French Tech"
            />
          </div>
          <div css={{ width: 250, display: 'flex', justifyContent: 'center' }}>
            <StaticImage
              src="../../data/images/partners/edtechfrance.png"
              // fadeIn={false}
              // placeholder="none"
              css={{
                marginBottom: 62,
                height: 70,
                width: 70,
                [mediaQueries.tabletLandscapeUp]: {
                  marginBottom: 40,
                },
              }}
              alt="Logo EdTech France"
            />
          </div>
          {/* <div css={{ width: 250, display: 'flex', justifyContent: 'center' }}>
            <StaticImage
              src="../../data/images/partners/logo-afia.png"
              fadeIn={false}
              css={{
                marginBottom: 62,
                width: 150,
                [mediaQueries.tabletLandscapeUp]: {
                  marginBottom: 40,
                },
              }}
              alt="Logo Afia CFA France"
            />
          </div> */}
        </div>
      </div>
    </Section>
  );
});

export default PartnersSection;
